<template>
	<main class="module-content">
		<h3>Wählen Sie eine Kategorie.</h3>
		<div class="results">
			<ul>
				<li v-for="category in categoriesList" :key="category.id" @click="prepareCurrentCategory(category.id)">
					<!-- <li v-for="category in this.getCategoriesList()" :key="category.id" @click="searchInputCategorie(category.name)"> -->
					{{ category.name }}
				</li>
			</ul>
		</div>
		<div class="loading" v-if="this.loading">
			<div><i class="fas fa-spinner fa-spin"></i> Kategorien werden geladen.</div>
		</div>
	</main>
</template>

<script>
export default {
	data: function () {
		return {
			loading: false,
			categoriesList: false,
		};
	},
	methods: {
		prepareCurrentCategory(categoryId) {
			this.$store.commit('prepareCurrentCategory', {
				categoryId: categoryId,
			});
			this.$store.commit('closeModule');
		},

		setCategories() {
			if (this.categoriesList) return;

			this.loading = true;
			let allCategories = [];

			// Load the hangers from backend
			// fetch('https://weber3000.de/wp-json/rwb-hanger/v2/hanger/')
			// fetch('https://weber-konfigurator-2022.lwa-dev.de/json-database/index.php?f=categories-schema')
			// fetch('http://127.0.0.1:5500/src/json-database/categories-schema.json')
			fetch(this.$store.state.settings.jsonCategoriesUrl)
				.then((r) => r.json())
				.then((response) => {
					for (let category of Object.entries(response.categories)) {
						if (category[1].status !== 'published') continue;
						allCategories.push(category[1]);
					}
					this.loading = false;
				})
				.catch((error) => {
					console.error('Error:', error);
				});

			this.categoriesList = allCategories;
		},
	},

	mounted() {
		this.setCategories();
	},
};
</script>

<!-- <style lang="scss">
#overlay-module {
	.categories {
		ul {
			list-style: none;
			margin: 0;
			padding: 1em 0;
			font-size: 0.8em;

			li {
				position: relative;
				padding: 1.2rem 0.8rem 1.2rem;
				border-bottom: 2px solid var(--clr-grey-200);
				cursor: pointer;
				transition: 0.3s;

				@media (min-width: 576px) {
					padding-top: 0.8rem;
					padding-bottom: 0.8rem;
					border-bottom-width: 1px;
				}

				&:hover,
				&:focus {
					color: var(--clr-red-400);
					background: var(--clr-grey-200);
				}

				&:last-child {
					border-bottom: 0;
				}
			}
		}
	}
}
</style> -->
