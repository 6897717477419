<template>
  <div class="bg" @click="closeModule"></div>
</template>

<script>
export default {
  methods: {
    closeModule() {
      this.$store.commit('closeModule')
    }
  }
}
</script>

<style lang="scss">
#overlay-module .bg {
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(190, 190, 190, 0.3);
}
</style>
