<template>
  <div class="btn-close-module">
    <button @click="closeModule"></button>
  </div>
</template>

<script>
export default {
  methods: {
    closeModule() {
      this.$store.commit('closeModule')
    }
  }
}
</script>

<style lang="scss">
.module .btn-close-module button {
  position: absolute;
  z-index: 150;
  top: 1.3rem;
  right: 1.3rem;
  width: 42px;
  height: 42px;
  appearance: none;
  border: 1px solid var(--clr-green-400);
  color: var(--clr-green-400);
  border-radius: 50%;
  outline: 0;
  background: transparent;
  cursor: pointer;
  transition: all 0.3s;

  &:hover {
    background: var(--clr-green-400);
    color: #fff;
    transform: rotate(90deg) scale(1.2);
  }

  &::before {
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    font-family: "Font Awesome 5 Pro";
    font-weight: 300;
    font-size: 26px;
    content: "\f00d";
  }
}
</style>
