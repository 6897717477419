<template>
	<div class="hanger-image">
		<div v-for="item in this.imageLayers" :key="item.class" :class="item.class">
			<img v-if="item.img" :src="item.img" :alt="item.class" />
		</div>
		<!-- <div
      v-if="!isPreview"
      class="image-wrapper"
      v-html="currentHanger.images.front"
    ></div>
    <div v-else class="preview-wrapper">
      <img src="@/assets/preview-default/body.svg" class="product-body" />
      <div class="hook" v-if="currentHookPreviewImage">
        <img
          :src="
            require('@/assets/preview-default/' +
              currentHookPreviewImage +
              '.svg')
          "
          class="product-body"
        />
      </div>
      <div class="equipment" v-if="currentEquipmentPreviewImages">
        <img
          v-for="item in currentEquipmentPreviewImages"
          :key="item.id"
          :src="require('@/assets/preview-default/' + item.id + '.svg')"
          class="product-equipment"
        />
      </div>
    </div> -->
	</div>
</template>

<script>
export default {
	name: 'HangerImage',

	computed: {
		imageLayers() {
			let layers = [];
			let images = [];
			let imageZIndex = this.$store.state.settings['image-z-index'];
			let currentCategory = this.$store.state.currentCategory;

			let currentColorId = this.$store.state.currentHangerSelection.colors;
			let currentShapeId = this.$store.state.currentHangerSelection.shapes;
			let currentSkirtCutsId = this.$store.state.currentHangerSelection['skirt-cuts'];
			let currentMaterialId = this.$store.state.currentHangerSelection.materials;
			let currentHangerColorImages = currentCategory.colors[currentColorId].images;
			images['colors'] = false;

			for (let image of Object.entries(currentHangerColorImages)) {
				if (currentShapeId != image[1].settings.shapes) continue;
				if (currentSkirtCutsId != image[1].settings['skirt-cuts']) continue;
				if (currentMaterialId != image[1].settings.materials) continue;

				images['colors'] = image[1].src;
			}

			let currentBarId = this.$store.state.currentHangerSelection.bars;
			if (currentCategory.bars) {
				let currentBarImageUrl = currentCategory.bars[currentBarId].image;
				images['bars'] = currentBarImageUrl;
			}

			// let currentClipBarId = this.$store.state.currentHangerSelection['clip-bars'];
			// if (currentCategory['clip-bars']) {
			// 	let currentClipBarImageUrl = currentCategory['clip-bars'][currentClipBarId].image;
			// 	images['clip-bars'] = currentClipBarImageUrl;
			// }

			let currentHookId = this.$store.state.currentHangerSelection.hooks;
			if (currentCategory.hooks) {
				let currentHookImageUrl = currentCategory.hooks[currentHookId].image;
				images['hooks'] = currentHookImageUrl;
			}

			for (let imageLayer of Object.entries(imageZIndex)) {
				layers.push({
					class: imageLayer[1],
					img: images[imageLayer[1]],
				});
			}

			return layers;
		},
		// isPreview() {
		//   return this.$store.state.preview
		// },
		// currentHanger() {
		//   return this.$store.state.currentHanger
		// },
		// currentHangerSelection() {
		//   return this.$store.state.currentHangerSelection
		// },
		// currentHookPreviewImage() {
		//   var currentIdFlag
		//   var previewImage = false
		//   // Get current idFlag
		//   for (let value of Object.entries(
		//     this.$store.state.currentHanger.productHook
		//   )) {
		//     if (value[1].key === this.$store.state.currentHangerSelection.hook) {
		//       if (value[1].idFlag) {
		//         currentIdFlag = value[1].idFlag.toLowerCase()
		//         break
		//       }
		//     }
		//   }
		//   // Rewite ID
		//   currentIdFlag = this.rewriteHookId(currentIdFlag)
		//   // Get all Image Assets
		//   const images = require.context('@/assets/preview-default/', true, /.svg$/)
		//   // If Asset exists return ID
		//   images.keys().forEach(filename => {
		//     let rawFilename = filename.replace('./', '').replace('.svg', '')
		//     if (currentIdFlag === rawFilename) {
		//       previewImage = rawFilename
		//     }
		//   })
		//   // console.log(currentIdFlag, previewImage)
		//   return previewImage
		// },
		// currentEquipmentPreviewImages() {
		//   if (!this.$store.state.currentHangerSelection.equipment) {
		//     return false
		//   }
		//   var previewImages = new Array()
		//   let currentIdFlags = new Array()
		//   // Get current idFlag
		//   for (let value of Object.entries(
		//     this.$store.state.currentHanger.productEquipment
		//   )) {
		//     if (
		//       this.$store.state.currentHangerSelection.equipment &&
		//       this.$store.state.currentHangerSelection.equipment.includes(
		//         value[1].key
		//       )
		//     ) {
		//       if (value[1].idFlag) {
		//         currentIdFlags.push(value[1].idFlag.toLowerCase())
		//       }
		//     }
		//   }
		//   // Get all Image Assets
		//   const images = require.context('@/assets/preview-default/', true, /.svg$/)
		//   // If Asset exists return ID
		//   images.keys().forEach(filename => {
		//     let rawFilename = filename.replace('./', '').replace('.svg', '')
		//     if (currentIdFlags.includes(rawFilename)) {
		//       previewImages.push({ id: rawFilename })
		//     }
		//   })
		//   // console.log(currentIdFlags, previewImages)
		//   return previewImages
		// }
	},
	methods: {
		// rewriteHookId(id) {
		//   // Rewite ID
		//   id = id === 'ni' ? 'hook' : id
		//   id = id === 'me' ? 'hook' : id
		//   return id
		// }
	},
};
</script>

<style lang="scss" scoped>
.hanger-image {
	position: relative;
	grid-area: hangerImage;
	margin: 2rem 0;
	background: linear-gradient(110deg, rgba(0, 0, 0, 0.05), transparent 85%);
	border-radius: 16px;

	@media (min-width: 768px) {
		margin: 0 0 0 1rem;
	}
}

.hanger-image div {
	position: absolute;
	top: 0;
	width: 100%;
	text-align: center;
	font-size: 0;
}

.hanger-image div:last-child {
	position: relative;
}

.hanger-image img {
	width: 100%;
	max-width: 970px;
}
// @import '@/scss/colors';
// #hanger-image {
//   position: relative;
//   height: 0;
//   padding-bottom: 70%;

//   @media (min-width: 576px) {
//     min-height: 40vh;
//     padding-bottom: 0;
//   }

//   .image-wrapper,
//   .preview-wrapper {
//     position: absolute;
//     top: 0;
//     right: 1.5em;
//     bottom: 0;
//     left: 1.5em;

//     img {
//       display: block;
//       height: 100%;
//       width: 100%;
//       margin: 0 auto;
//       object-fit: contain;
//       filter: brightness(1.1);
//     }

//     > div {
//       position: absolute;
//       position: absolute;
//       top: 0;
//       right: 0;
//       bottom: 0;
//       left: 0;

//       img {
//         position: absolute;
//       }
//     }
//   }
// }
</style>
