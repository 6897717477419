import Vue from 'vue';
import VueRouter from 'vue-router';
import Configurator from '../views/Configurator.vue';

Vue.use(VueRouter);

const routes = [
	{
		path: '/',
		name: 'Configurator',
		component: Configurator,
		props: (route) => ({ query: route.query.q }),
	},
	// {
	//   path: '/imprint',
	//   name: 'Imprint',
	//   // route level code-splitting
	//   // this generates a separate chunk (about.[hash].js) for this route
	//   // which is lazy-loaded when the route is visited.
	//   component: () =>
	//     import(/* webpackChunkName: "imprint" */ '../views/Imprint.vue')
	// },
	// {
	//   path: '/howto',
	//   name: 'HowTo',
	//   // route level code-splitting
	//   // this generates a separate chunk (about.[hash].js) for this route
	//   // which is lazy-loaded when the route is visited.
	//   component: () =>
	//     import(/* webpackChunkName: "disclaimer" */ '../views/HowTo.vue')
	// },
	// {
	//   path: '/disclaimer',
	//   name: 'Disclaimer',
	//   // route level code-splitting
	//   // this generates a separate chunk (about.[hash].js) for this route
	//   // which is lazy-loaded when the route is visited.
	//   component: () =>
	//     import(/* webpackChunkName: "disclaimer" */ '../views/Disclaimer.vue')
	// },
	// {
	//   path: '/dataprotection',
	//   name: 'DataProtection',
	//   // route level code-splitting
	//   // this generates a separate chunk (about.[hash].js) for this route
	//   // which is lazy-loaded when the route is visited.
	//   component: () =>
	//     import(
	//       /* webpackChunkName: "dataprotection" */ '../views/DataProtection.vue'
	//     )
	// }
];

const router = new VueRouter({
	mode: 'history',
	routes,
});

export default router;
