<template>
	<div class="settings settings-additional">
		<h3>Zusatzausstattung</h3>
		<div class="buttons">
			<HangerConfiguratorButtonDefault attributeSlug="skirt-cuts" attributeLabel="Rockeinschnitte" />
			<HangerConfiguratorButtonDefault attributeSlug="bars" attributeLabel="Steg" />
			<!-- <HangerConfiguratorButtonDefault attributeSlug="clip-bars" attributeLabel="Klammersteg" /> -->
			<HangerConfiguratorButtonDefault attributeSlug="ribbed-inlays" attributeLabel="Rippeneinlage" />
			<HangerConfiguratorButtonDefault attributeSlug="non-slip-lacquers" attributeLabel="Anti-Rutsch Lackierung" />
			<HangerConfiguratorButtonDefault attributeSlug="soft-touch-lacquers" attributeLabel="Soft Touch Lackierung" />
			<HangerConfiguratorButtonDefault attributeSlug="flockings" attributeLabel="Beflockung" />
			<!-- <HangerConfiguratorButtonReset /> -->
		</div>
	</div>
</template>

<script>
// @ is an alias to /src
import HangerConfiguratorButtonDefault from '@/components/hanger-configurator/ButtonDefault.vue';
// import HangerConfiguratorButtonReset from '@/components/hanger-configurator/ButtonReset.vue';

export default {
	name: 'HangerSettings',

	components: {
		HangerConfiguratorButtonDefault,
		// HangerConfiguratorButtonReset,
	},
};
</script>

<style lang="scss">
#hanger-configurator {
	.settings-additional {
		grid-area: settingsAdditional;
		margin-top: 2rem;

		button {
			display: block;
			width: 100%;
			white-space: normal;
			line-height: 1.2;

			@media (min-width: 768px) {
				max-width: 270px;
			}
		}

		button:last-child {
			margin-bottom: 0;
		}
	}
}
</style>
