<template>
	<div class="settings settings-general">
		<!-- <div class="buttons" v-if="!this.loading"> -->
		<h3>Grundauswahl</h3>
		<div class="buttons">
			<HangerConfiguratorButtonCategory />
			<HangerConfiguratorButtonDefault attributeSlug="shapes" attributeLabel="" />
			<HangerConfiguratorButtonDefault attributeSlug="hooks" attributeLabel="Haken" />
			<HangerConfiguratorButtonDefault attributeSlug="materials" attributeLabel="Material" />
			<HangerConfiguratorButtonDefault attributeSlug="colors" attributeLabel="Farbe" />
			<HangerConfiguratorButtonDefault attributeSlug="lengths" attributeLabel="Länge" />
			<HangerConfiguratorButtonDefault attributeSlug="heights" attributeLabel="Höhe" />
			<HangerConfiguratorButtonDefault attributeSlug="thicknesses" attributeLabel="Stärke" />
		</div>
		<!-- <div v-else class="loading">
      <div>
        <i class="fas fa-spinner fa-spin"></i> Kleiderbügel Informationen werden
        geladen.
      </div>
    </div> -->
	</div>
</template>

<script>
// @ is an alias to /src
import HangerConfiguratorButtonCategory from '@/components/hanger-configurator/ButtonCategory.vue';
import HangerConfiguratorButtonDefault from '@/components/hanger-configurator/ButtonDefault.vue';

export default {
	name: 'HangerSettings',
	// computed: {
	// loading() {
	//   return this.$store.state.currentHangerIsLoading ? true : false
	// }
	// },

	components: {
		HangerConfiguratorButtonCategory,
		HangerConfiguratorButtonDefault,
	},

	methods: {
		// Preselect attributes form URL
		preselectAttributesFormUrl() {
			if (this.$route.query) {
				let attributeKeys = this.$store.state.settings['flag-order'];

				// Preselect Attribute
				let value = '';
				for (let key of Object.entries(attributeKeys)) {
					value = this.$route.query[key[1]];
					if (value) {
						this.$store.commit('setCurrentHangerSelection', {
							key: key[1],
							value: value,
						});
					}
				}
			}
		},
	},

	mounted() {
		this.preselectAttributesFormUrl();
		this.$store.dispatch('checkConditions');
	},
};
</script>

<style lang="scss">
#hanger-configurator {
	.settings {
		line-height: 1;
		// display: flex;
		// flex-wrap: wrap;
		// justify-content: center;
		grid-area: settingsGeneral;

		h3 {
			font-size: 1rem;
			font-weight: 600;
			text-transform: uppercase;
			margin: 0 0 0.8rem;
		}

		// .buttons {
		// 	display: contents;
		// }

		button {
			position: relative;
			height: auto;
			appearance: none;
			margin: 0 0.6rem 0.6rem 0;
			padding: 0.9em 0.9em 0.7em;
			border: 1px solid var(--clr-grey-400);
			border-radius: 100px;
			outline: 0;
			font-size: 0.9rem;
			font-weight: 600;
			text-align: left;
			white-space: nowrap;
			cursor: default;
			color: var(--clr-grey-300);
			background: repeating-linear-gradient(45deg, transparent, transparent 3px, rgba(0, 0, 0, 0.05) 3px, rgba(0, 0, 0, 0.05) 6px);
			transition: all 0.3s;

			display: block;
			width: 100%;
			line-height: 1.2;

			@media (min-width: 768px) {
				display: inline-block;
				width: auto;
			}

			.label::after {
				content: ': ';
			}

			// @media (min-width: 576px) {
			//   min-width: auto;
			// }

			// &.hasThumbnail {
			//   margin-top: 65px;

			//   @media (min-width: 576px) {
			//     margin-top: 1.5em;
			//   }
			// }

			// .thumbnail {
			// 	display: none;
			// 	// position: absolute;
			// 	// bottom: 100%;
			// 	// left: 2px;
			// 	// right: 2px;
			// 	// height: 60px;
			// 	// border: 1px solid rgba(0, 0, 0, 0.1);
			// 	// border-radius: 4px 4px 0 0;
			// 	// overflow: hidden;
			// 	// transition: 0.2s;

			// 	// @media (min-width: 576px) {
			// 	//   height: 14px;
			// 	//   bottom: calc(100% + 2px);
			// 	//   border-radius: 4px;
			// 	// }

			// 	// img {
			// 	//   display: block;
			// 	//   width: 100%;
			// 	//   height: 100%;
			// 	//   object-fit: cover;
			// 	// }
			// }

			// @media (min-width: 576px) {
			// 	&:hover {
			// 		.thumbnail {
			// 			bottom: 100%;
			// 			border-radius: 4px 4px 0 0;
			// 			transition: all 1s, bottom 0.2s;
			// 			transition-delay: 0.3s;
			// 			height: 120px;
			// 		}
			// 	}
			// }

			// .thumbnail-list {
			// 	list-style: none;
			// 	margin: 0;
			// 	padding: 0;
			// 	font-size: 0;
			// 	position: absolute;
			// 	bottom: 100%;
			// 	left: 50%;
			// 	transform: translateX(-50%);
			// 	white-space: nowrap;
			// 	transition: 0.2s;

			// 	li {
			// 		display: inline-block;
			// 		height: 60px;
			// 		width: 60px;
			// 		margin: 0 0 2px;
			// 		border: 1px solid rgba(0, 0, 0, 0.1);
			// 		border-radius: 50%;
			// 		overflow: hidden;
			// 		transition: 0.2s;
			// 		background: #fff;

			// 		@media (min-width: 576px) {
			// 			height: 18px;
			// 			width: 18px;
			// 		}

			// 		img {
			// 			display: block;
			// 			width: 100%;
			// 			height: 100%;
			// 			object-fit: cover;
			// 		}
			// 	}
			// }

			// @media (min-width: 576px) {
			// 	&:hover {
			// 		.thumbnail-list {
			// 			li {
			// 				margin: 0 2px 2px;
			// 				width: 120px;
			// 				height: 78px;
			// 				border-radius: 4px;
			// 				transition: all 1s, bottom 0.2s;
			// 				transition-delay: 0.3s;
			// 			}
			// 		}
			// 	}
			// }

			&.isEditable {
				background: transparent;
				color: var(--clr-green-400);
				border-color: var(--clr-green-400);
				cursor: pointer;
				padding-left: 1rem;
				padding-right: 2.1rem;
				position: relative;

				&::after {
					position: absolute;
					right: 0.9rem;
					top: 50%;
					transform: translateY(-50%);
					display: inline-block;
					font-style: normal;
					font-variant: normal;
					text-rendering: auto;
					-webkit-font-smoothing: antialiased;
					font-family: 'Font Awesome 5 Pro';
					font-weight: 300;
					content: '\f078';
				}

				&:hover {
					color: var(--clr-red-400);
					border-color: var(--clr-red-400);
				}
			}

			&.toggleButton {
				.label::after {
					display: none;
				}
				.value {
					display: none;
				}

				&::after {
					content: '\f111';
				}

				&.checked::after {
					content: '\f058';
					font-weight: 600;
				}
			}
		}
	}
}
</style>
