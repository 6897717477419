<template>
	<main class="module-content" v-bind:class="classObject">
		<h3>Wählen Sie eine Stärke.</h3>
		<div class="results" v-if="results">
			<ul>
				<li v-for="(item, index) in this.results" :key="index" @click="setCurrentHangerAttribute(index)">
					<!-- <img class="thumbnail" :src="item.image" @error="imageLoadError" /> -->
					<span class="label"> {{ item }} {{ unit }} </span>
				</li>
			</ul>
		</div>
	</main>
</template>

<script>
export default {
	data: function () {
		return {
			results: Object,
			unit: this.$store.state.currentCategory['thicknesses-unit'],
			// fallbackThumbnail: require('@/assets/logo-weber-kleiderbuegel.svg'),
		};
	},

	computed: {
		classObject() {
			return {
				'has-tumbnails': true,
			};
		},
	},

	methods: {
		setCurrentHangerAttribute(value) {
			this.$store.commit('setCurrentHangerSelection', {
				key: 'thicknesses',
				value: value,
			});
			this.$store.dispatch('checkConditions');
			this.$store.commit('closeModule');
		},

		/**
		 * Prevent broken images with fallback thumbnail
		 */
		// imageLoadError(event) {
		// 	event.target.src = this.fallbackThumbnail;
		// 	console.log('Image failed to load and is replaced with fallback thumbnail.');
		// },
	},

	mounted() {
		this.results = this.$store.state.currentCategory.thicknesses;
	},
};
</script>

<style lang="scss">
// @media (min-width: 576px) {
// 	#overlay-module.shape .module {
// 		max-width: 500px;
// 	}
// }

#overlay-module.thicknesses .module .module-content.has-tumbnails .results {
	--columns: 2;
	--gap: 0.5rem;
}
</style>
