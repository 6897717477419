import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import BootstrapVue from 'bootstrap-vue';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import './assets/fontawesome-pro/css/all.min.css';
import './fonts/josefin-sans.css';

Vue.config.productionTip = false;

new Vue({
	router,
	store,
	BootstrapVue,
	render: (h) => h(App),
}).$mount('#app');
