<template>
	<div class="inqire">
		<a :href="targetUrl" class="btn">Jetzt anfragen</a>
	</div>
</template>

<script>
export default {
	name: 'HangerButtonInquire',
	props: ['itemNumber'],
	data: function () {
		return {
			url: 'https://weber-kleiderbuegel.lwa-wip.de/anfrage',
		};
	},
	computed: {
		targetUrl() {
			let currentURL = 'url=' + encodeURIComponent(window.location.href);
			let itemNumber = 'number=' + encodeURIComponent(this.itemNumber.trim());
			return this.url + '?' + itemNumber + '&' + currentURL;
		},
	},
};
</script>

<style lang="scss" scoped>
.inqire {
	grid-area: inqire;
	width: 100%;
	margin: 1rem 0 0;
}

.btn {
	position: relative;
	width: 100%;
	margin: 0;
	padding: 0.8em 1.5em 0.5em 0.5em;
	border-radius: 100px;
	font-size: 1rem;
	font-weight: 600;
	text-transform: uppercase;
	white-space: nowrap;
	color: var(--clr-white);
	background: var(--clr-red-400);
	transition: all 0.3s;

	&::after {
		position: absolute;
		right: 0.9rem;
		top: 50%;
		transform: translateY(-50%);
		display: inline-block;
		font-style: normal;
		font-variant: normal;
		text-rendering: auto;
		-webkit-font-smoothing: antialiased;
		font-family: 'Font Awesome 5 Pro';
		font-weight: 300;
		content: '\f054';
	}

	&:hover {
		color: #fff;
		background: var(--clr-green-400);
	}
}
</style>
