import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
	state: {
		currentModule: false,
		settings: {
			'image-z-index': {
				1: 'bars',
				2: 'clip-bars',
				3: 'hooks',
				4: 'colors',
			},
			'flag-order': {
				0: 'shapes',
				1: 'lengths',
				2: 'skirt-cuts',
				3: 'bars',
				4: 'clip-bars',
				5: 'ribbed-inlays',
				6: 'non-slip-lacquers',
				7: 'soft-touch-lacquers',
				8: 'flockings',
				9: 'colors',
				10: 'materials',
				11: 'hooks',
				12: 'heights',
				13: 'thicknesses',
			},
			// jsonCategoriesUrl: 'http://127.0.0.1:5500/src/json-database/categories-schema.json',
			jsonCategoriesUrl: 'https://weber-kleiderbuegel.lwa-wip.de/json-categories-schema',
			// jsonCategoriesUrl: 'https://weber-konfigurator-2022.lwa-dev.de/json-database/categories-schema.json',
			// jsonCategoryUrl: 'http://127.0.0.1:5500/src/json-database/category-schema.json',
			jsonCategoryUrl: 'https://weber-kleiderbuegel.lwa-wip.de/json-category-schema',
			// jsonCategoryUrl: 'https://weber-konfigurator-2022.lwa-dev.de/json-database/category-schema.json',
		},
		itemNumber: String,

		currentCategoryIsLoading: false,
		currentCategory: Object,
		currentCategoryOriginal: Object,
		currentHangerSelection: {
			// names: String,
			shapes: Number,
			'skirt-cuts': Number,
			materials: Number,
			lengths: Number,
			heights: Number,
			thicknesses: Number,
			colors: Array,
			hooks: Number,
			bars: Number,
			'clip-bars': Number,
			'ribbed-inlays': Number,
			'non-slip-lacquers': Number,
			'soft-touch-lacquers': Number,
			flockings: Number,
		},
	},
	mutations: {
		/**
		 * Module
		 */
		switchModule(state, payload) {
			state.currentModule = payload;
		},

		closeModule(state) {
			state.currentModule = false;
		},

		/**
		 * General
		 */
		setCurrentCategoryIsLoading(state, payload) {
			state.currentCategoryIsLoading = payload.state;
		},

		setCurrentCategory(state, payload) {
			state.currentCategory = payload;

			const url = new URL(window.location);
			url.searchParams.set('cid', payload['category-id']);
			window.history.pushState({}, '', url);
		},

		setItemNumber(state, payload) {
			state.itemNumber = payload;
		},

		setSettings(state, payload) {
			state.settings = payload.state;
		},

		/**
		 * Set Current Category Values
		 */
		setCurrentHangerSelection(state, payload) {
			// console.log('setCurrentHangerSelection', payload);
			state.currentHangerSelection[payload.key] = parseInt(payload.value);

			const url = new URL(window.location);
			url.searchParams.set(payload.key, payload.value);
			window.history.pushState({}, '', url);
		},

		prepareCurrentCategory(state, payload) {
			state.currentCategoryIsLoading = true;
			let currentCategory = payload.categoryId;

			// Load the hangers from backend
			// fetch('https://weber3000.de/wp-json/rwb-hanger/v2/hanger/')
			// fetch('https://weber-konfigurator-2022.lwa-dev.de/json-database/index.php?f=categories-schema')
			// Hier muss noch die richtige Datei für die entsprechende Kategorie geladen werden.
			fetch(state.settings.jsonCategoryUrl + '?id=' + currentCategory)
				.then((r) => r.json())
				.then((response) => {
					state.currentCategory = response;
					state.currentCategoryOriginal = response;
					state.currentHangerSelection = response.defaults;
					state.currentCategoryIsLoading = false;
					// console.log('Category JSON is loaded.');
				})
				.catch((error) => {
					console.error('Error:', error);
				});
		},
	},

	actions: {
		checkConditions() {
			// Reset Category
			const currentCategoryOriginal = JSON.parse(JSON.stringify(this.state.currentCategoryOriginal));
			this.commit('setCurrentCategory', currentCategoryOriginal);

			// Loop category Conditions
			for (let currCondition of Object.entries(this.state.currentCategoryOriginal.conditions)) {
				let relationCompare = currCondition[1].relation.compare;
				let relationKey = currCondition[1].relation.key;
				let relationValues = currCondition[1].relation.value;
				let restrictions = currCondition[1].restrictions;

				// Convert single values to array
				if (!Array.isArray(relationValues)) {
					relationValues = [relationValues];
				}

				// is equal
				if ('=' == relationCompare) {
					// Loop and detect relations
					for (let rValue of relationValues) {
						if (rValue == this.state.currentHangerSelection[relationKey]) {
							// Relation detectet, now check restrictions
							// console.log('Equal relation detectet, now check restrictions.');
							this.dispatch('checkRestrictions', { restrictions });
							break; // one relation is enough, so break loop
						}
					}
				}

				// is NOT equal
				if ('!=' == relationCompare) {
					// Loop and detect relations
					for (let rValue of relationValues) {
						if (rValue != this.state.currentHangerSelection[relationKey]) {
							// Relation detectet, now check restrictions
							// console.log('Not equal relation detectet, now check restrictions.');
							this.dispatch('checkRestrictions', { restrictions });
							break; // one relation is enough, so break loop
						}
					}
				}
			}

			this.dispatch('resetMissingAttributes');
		},

		checkRestrictions(state, payload) {
			for (let currRestriction of Object.entries(payload.restrictions)) {
				let restrictionKey = currRestriction[1].key;
				let restrictionEnabled = currRestriction[1].enabled;
				let restrictionDisabled = currRestriction[1].disabled;

				// Enable Attributes
				if (restrictionEnabled) {
					this.dispatch('enableAttributes', { restrictionEnabled, restrictionKey });
				}

				// Disable Attributes
				if (restrictionDisabled) {
					this.dispatch('disableAttributes', { restrictionDisabled, restrictionKey });
				}
			}
		},

		enableAttributes(state, payload) {
			let filteredCategory = this.state.currentCategory;

			// Disable all
			if (payload.restrictionEnabled === false) {
				// console.log('DISABLED:', payload.restrictionKey);
				delete filteredCategory[payload.restrictionKey];
			}

			// Enable all
			if (payload.restrictionEnabled === true) {
				// console.log('ENABLED', payload.restrictionKey);
				this.commit('setCurrentCategory', filteredCategory);
				return;
			}

			// Remove all except enabled attributes
			if (payload.restrictionEnabled.length > 0) {
				for (let elementKey of Object.keys(filteredCategory[payload.restrictionKey])) {
					elementKey = parseInt(elementKey);
					if (!payload.restrictionEnabled.includes(elementKey)) {
						// console.log('Delete:', elementKey, filteredCategory[payload.restrictionKey][elementKey]);
						delete filteredCategory[payload.restrictionKey][elementKey];
					}
				}
			}

			this.commit('setCurrentCategory', filteredCategory);
		},

		disableAttributes(state, payload) {
			let filteredCategory = this.state.currentCategory;

			// Enable all
			if (payload.restrictionDisabled === false) {
				// console.log('ENABLED', payload.restrictionKey);
				this.commit('setCurrentCategory', filteredCategory);
				return;
			}

			// Disable all
			if (payload.restrictionDisabled === true) {
				// console.log('DISABLED:', payload.restrictionKey);
				delete filteredCategory[payload.restrictionKey];
			}

			// Remove disabled attributes
			if (payload.restrictionDisabled.length > 0) {
				for (let elementKey of payload.restrictionDisabled) {
					// console.log('Delete:', payload.restrictionKey, filteredCategory[payload.restrictionKey][elementKey]);
					delete filteredCategory[payload.restrictionKey][elementKey];
				}
			}

			this.commit('setCurrentCategory', filteredCategory);
		},

		resetMissingAttributes() {
			let attributeKeys = this.state.settings['flag-order'];

			// Test every attribute
			for (let key of Object.entries(attributeKeys)) {
				let currentCategoryAttributes = this.state.currentCategory[key[1]];

				// Missing attribute? Continue with next.
				if (!currentCategoryAttributes) continue;

				// Selected item does not exist
				let currentHangerSelectionId = this.state.currentHangerSelection[key[1]];
				let categoryItem = currentCategoryAttributes[currentHangerSelectionId];
				if (!categoryItem) {
					// Set first attribute as selected
					for (let attribute of Object.entries(currentCategoryAttributes)) {
						let attributeKey = attribute[0];
						this.state.currentHangerSelection[key[1]] = attributeKey;
						console.log('resetMissingAttributes', key[1], attributeKey);
						break;
					}
				}
			}
		},
	},
});
