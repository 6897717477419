<template>
	<main class="module-content" v-bind:class="classObject">
		<h3>Wählen Sie einen Steg.</h3>
		<div class="results" v-if="results">
			<ul>
				<template v-for="(item, index) in this.results">
					<li v-if="item.category" class="parent" :key="'parent-' + index">
						<span class="title">{{ item.category }}</span>

						<ul class="childs">
							<template v-for="(childItem, childIndex) in item.elements">
								<li v-if="childItem" :key="'child-' + childIndex" @click="setCurrentHangerAttribute(childIndex)" :class="{ 'no-thumbnail': !item.image }">
									<!-- <span class="label" @click="setCurrentHangerAttribute(childIndex)"> -->
									<div class="thumbnail" v-if="childItem.image">
										<img class="thumbnail-image" :src="childItem.image" alt="Vorschau" />
									</div>
									<span class="label">
										{{ childItem.label }}
										<span v-if="childItem.flag" class="flag">({{ childItem.flag }})</span>
									</span>
								</li>
							</template>
						</ul>
					</li>
					<li v-else :key="index" @click="setCurrentHangerAttribute(item.index)" :class="{ 'no-thumbnail': !item.image }">
						<div class="thumbnail" v-if="item.elements[0].image">
							<img class="thumbnail-image" :src="item.elements[0].image" alt="Vorschau" />
						</div>

						<span class="label">
							{{ item.elements[0].label }}
							<span v-if="item.elements[0].flag" class="flag">({{ item.elements[0].flag }})</span>
						</span>
					</li>
				</template>
			</ul>
		</div>
	</main>
</template>

<script>
export default {
	data: function () {
		return {
			results: Object,
			// fallbackThumbnail: require('@/assets/logo-weber-kleiderbuegel.svg'),
		};
	},

	computed: {
		classObject() {
			return {
				'has-tumbnails': true,
			};
		},
	},

	methods: {
		setCurrentHangerAttribute(value) {
			this.$store.commit('setCurrentHangerSelection', {
				key: 'bars',
				value: value,
			});
			this.$store.dispatch('checkConditions');
			this.$store.commit('closeModule');
		},

		itemsInCategories() {
			let elements = this.$store.state.currentCategory.bars;
			let newItems = [];

			for (let [index, currentColor] of Object.entries(elements)) {
				if (!currentColor.category) {
					// No category
					newItems[index] = [];
					newItems[index]['index'] = index;
					newItems[index]['elements'] = [currentColor];
					newItems[index]['category'] = false;
				} else {
					// Inside category
					if (!newItems[currentColor.category]) {
						newItems[currentColor.category] = [];
						newItems[currentColor.category]['index'] = index;
						newItems[currentColor.category]['elements'] = [];
						newItems[currentColor.category]['category'] = currentColor.category;
					}
					newItems[currentColor.category]['elements'][index] = currentColor;
				}
			}

			// New indexes
			let restructuredArr = [];
			for (let key in newItems) {
				restructuredArr.push(newItems[key]);
			}
			newItems = restructuredArr;

			return newItems;
		},
	},

	mounted() {
		this.results = this.itemsInCategories();
		// this.results = this.$store.state.currentCategory.bars;
	},
};
</script>

<style lang="scss">
// @media (min-width: 576px) {
// 	#overlay-module.shape .module {
// 		max-width: 500px;
// 	}
// }

#overlay-module.bars .module .module-content.has-tumbnails .results .thumbnail img {
	width: 350px;
	height: 350px;
	transform: translate(-50%, -71%);
}

#overlay-module.bars .module .module-content.has-tumbnails .results {
	--columns: 2;

	@media (min-width: 576px) {
		--columns: 3;
	}
}

#overlay-module.bars .module .module-content.has-tumbnails .results > ul > li:first-child {
	grid-column-start: 1;
	grid-column-end: -1;
	padding-top: 1.25rem;
	padding-bottom: 1.25rem;
}

#overlay-module.bars .module .module-content.has-tumbnails .results .parent:nth-child(3) .thumbnail img {
	width: 300px;
	height: 300px;
	transform: translate(-28%, -75%);
}
</style>
