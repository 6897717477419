<template>
	<main class="module-content" v-bind:class="classObject">
		<h3>Wählen Sie eine Anti-Rutsch Lackierung.</h3>
		<div class="results" v-if="results">
			<ul>
				<li v-for="(item, index) in this.results" :key="index" @click="setCurrentHangerAttribute(index)" :class="{ 'no-thumbnail': !item.image }">
					<!-- <img class="thumbnail" :src="item.image" @error="imageLoadError" /> -->
					<span class="label">
						{{ item.label }}
						<span v-if="item.flag" class="flag">({{ item.flag }})</span>
					</span>
				</li>
			</ul>
		</div>
	</main>
</template>

<script>
export default {
	data: function () {
		return {
			results: Object,
			// fallbackThumbnail: require('@/assets/logo-weber-kleiderbuegel.svg'),
		};
	},

	computed: {
		classObject() {
			return {
				'has-tumbnails': true,
			};
		},
	},

	methods: {
		setCurrentHangerAttribute(value) {
			this.$store.commit('setCurrentHangerSelection', {
				key: 'non-slip-lacquers',
				value: value,
			});
			this.$store.dispatch('checkConditions');
			this.$store.commit('closeModule');
		},

		/**
		 * Prevent broken images with fallback thumbnail
		 */
		// imageLoadError(event) {
		// 	event.target.src = this.fallbackThumbnail;
		// 	console.log('Image failed to load and is replaced with fallback thumbnail.');
		// },
	},

	mounted() {
		this.results = this.$store.state.currentCategory['non-slip-lacquers'];
	},
};
</script>

<style lang="scss">
// @media (min-width: 576px) {
// 	#overlay-module.shape .module {
// 		max-width: 500px;
// 	}
// }

#overlay-module.non-slip-lacquers .module .module-content.has-tumbnails .results {
	--columns: 2;
}
</style>
