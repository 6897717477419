<template>
	<div class="category-selection">
		<!-- <div class="buttons" v-if="!this.loading"> -->
		<div class="buttons">
			<HangerConfiguratorButtonCategory />
		</div>
	</div>
</template>

<script>
import HangerConfiguratorButtonCategory from '@/components/hanger-configurator/ButtonCategory.vue';

export default {
	name: 'CategorySelection',
	components: {
		HangerConfiguratorButtonCategory,
	},
};
</script>

<style lang="scss" scoped>
.category-selection {
	line-height: 1;
	text-align: center;

	button {
		position: relative;
		height: auto;
		appearance: none;
		margin: 0;
		padding: 2.3em 4em 2.1em 3em;
		border: 1px solid var(--clr-green-400);
		border-radius: 100px;
		outline: 0;
		font-size: 1rem;
		font-weight: 600;
		text-transform: uppercase;
		white-space: nowrap;
		cursor: default;
		color: var(--clr-green-400);
		background: transparent;
		transition: all 0.3s;

		&::after {
			position: absolute;
			right: 0.9rem;
			top: 50%;
			transform: translateY(-50%);
			display: inline-block;
			font-style: normal;
			font-variant: normal;
			text-rendering: auto;
			-webkit-font-smoothing: antialiased;
			font-family: 'Font Awesome 5 Pro';
			font-weight: 300;
			content: '\f061';
		}

		&:hover {
			z-index: 10;
			color: var(--clr-red-400);
			border-color: var(--clr-red-400);
		}
	}
}
</style>
