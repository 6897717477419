<template>
	<!-- <div id="app" v-bind:class="{ 'module-visible': currentModule }"> -->
	<div id="app" v-bind:class="currentModule">
		<router-view />
	</div>
</template>

<script>
console.info('Developed by Lecking Werbeagentur, https://lecking-werbeagentur.de');
export default {
	computed: {
		currentModule() {
			if (this.$store.state.currentModule) {
				document.querySelector('body').classList.add('module-visible');
				return 'module-visible-' + this.$store.state.currentModule;
			} else {
				document.querySelector('body').classList.remove('module-visible');
			}

			return false;
		},
	},
	mounted() {
		let html = document.querySelector('html');
		html.setAttribute('lang', 'de');
	},
};
</script>

<style lang="scss">
:root {
	--clr-green-400: #003c2e;
	--clr-green-300: #a2b9b3;
	--clr-green-200: #edede8;
	--clr-red-400: #dc545b;
	--clr-grey-400: #3d4140;
	--clr-grey-300: #6a6d6c;
	--clr-grey-200: #edede7;
	--clr-beige-400: #f1ede7;
	--clr-black: #000;
	--clr-white: #fff;

	--clr-primary: var(--clr-green);
	--clr-secondary: var(--clr-red);
	--clr-text: var(--clr-grey);
}

html,
body {
	min-height: 100vh;
}

a {
	color: var(--clr-green-400);

	&:hover {
		color: var(--clr-red-400);
	}
}
// body > div .alert-danger a {
//   color: #000;

//   &:hover {
//     color: #000;
//   }
// }

#app {
	background: linear-gradient(110deg, var(--clr-green-200) 60%, transparent 60%);
	font-family: 'Josefin Sans', sans-serif;
	color: var(--clr-text);
	min-height: 100vh;

	// > div {
	//   > main,
	//   > .main-content {
	//     max-height: 100vh;
	//     overflow-x: auto;
	//     padding: 2em 0;
	//   }
	// }

	// > div {
	//   > header,
	//   > main,
	//   > .main-content,
	//   > footer {
	//     transition: all 0.3s;
	//   }
	// }

	// > div {
	//   height: 100%;
	//   display: flex;
	//   flex-flow: column;
	//   justify-content: flex-start;
	// }

	// .main-content {
	//   margin-bottom: auto;
	// }
}

body.module-visible {
	overflow: hidden;

	#app > div {
		> header,
		> main,
		> .main-content,
		> footer {
			filter: blur(5px);
			transition: all 0.7s;
		}
	}
}
</style>
