<template>
	<div id="overlay-module" v-bind:class="currentModule" v-if="this.$store.state.currentModule">
		<ModuleBackground />
		<div class="module">
			<ModuleCloseButton />
			<component v-bind:is="currentModule"></component>
		</div>
	</div>
</template>

<script>
// @ is an alias to /src
import ModuleBackground from '@/components/modules/ModuleBackground.vue';
import ModuleCloseButton from '@/components/modules/ModuleCloseButton.vue';

// Modules
import Category from '@/components/modules/ChooseCategory.vue';
import Shapes from '@/components/modules/ChooseShapes.vue';
import SkirtCuts from '@/components/modules/ChooseSkirtCuts.vue';
import Materials from '@/components/modules/ChooseMaterials.vue';
import Lengths from '@/components/modules/ChooseLengths.vue';
import Thicknesses from '@/components/modules/ChooseThicknesses.vue';
import Heights from '@/components/modules/ChooseHeights.vue';
import Colors from '@/components/modules/ChooseColors.vue';
import Hooks from '@/components/modules/ChooseHooks.vue';
import Bars from '@/components/modules/ChooseBars.vue';
import ClipBars from '@/components/modules/ChooseClipBars.vue';
import RibbedInlays from '@/components/modules/ChooseRibbedInlays.vue';
import NonSlipLacquers from '@/components/modules/ChooseNonSlipLacquers.vue';
import SoftTouchLacquers from '@/components/modules/ChooseSoftTouchLacquers.vue';
import Flockings from '@/components/modules/ChooseFlockings.vue';

export default {
	computed: {
		currentModule() {
			return this.$store.state.currentModule;
		},
	},
	components: {
		ModuleBackground,
		ModuleCloseButton,
		Category,
		Shapes,
		SkirtCuts,
		Materials,
		Lengths,
		Thicknesses,
		Heights,
		Colors,
		Hooks,
		Bars,
		ClipBars,
		RibbedInlays,
		NonSlipLacquers,
		SoftTouchLacquers,
		Flockings,
	},
};
</script>

<style lang="scss">
#overlay-module {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	display: flex;
	justify-content: center;
	align-items: center;

	@media (min-width: 576px) {
		&.category .module {
			max-width: 500px;
		}
	}

	.module {
		position: relative;
		background: #fff;
		padding: 5em 1em 3em;
		height: 100%;
		max-height: 100vh;
		width: 100%;
		overflow-x: auto;

		@media (min-width: 576px) {
			padding: 4em 3em 3em;
			box-shadow: 0 0 50px rgba(0, 0, 0, 0.5);
			border-radius: 2em;
			height: auto;
			max-height: 95vh;
			width: auto;
			max-width: 770px;
		}

		// .loading {
		// 	position: absolute;
		// 	z-index: 100;
		// 	top: 0;
		// 	right: 0;
		// 	bottom: 0;
		// 	left: 0;
		// 	background: #fff;
		// 	display: flex;
		// 	justify-content: center;
		// 	align-items: center;

		// 	i {
		// 		margin-right: 0.4em;
		// 		font-size: 2em;
		// 		vertical-align: middle;
		// 	}
		// }

		h3 {
			font-size: 1.8em;
			font-weight: 400;
			line-height: 1.4;
			margin: 0 0 1.5rem;
			padding: 0;
		}

		// input[type='text'] {
		// 	background: var(--clr-green-200);
		// 	display: block;
		// 	width: 100%;
		// 	margin: 0 0 1.5rem;
		// 	padding: 0.5em 1em;
		// 	border: 3px solid transparent;
		// 	border-radius: 4px;
		// 	outline: none;
		// 	transition: all 0.3s;

		// 	&:focus {
		// 		border-bottom-color: var(--clr-green-200);
		// 	}
		// }

		.results {
			--gap: 0;
			--columns: 1;

			overflow-x: auto;

			@media (min-width: 576px) {
				max-height: 60vh;
			}

			ul {
				margin: 0;
				padding: 0;
				border-left: 0;
				border-right: 0;
				list-style: none;
				display: grid;
				grid-template-columns: repeat(var(--columns), 1fr);
				gap: var(--gap);

				.flag {
					font-size: 80%;
					color: #aaa;
				}

				.thumbnail {
					display: none;
				}

				li:not(.parent) {
					// .child-results > li {
					display: block;
					position: relative;
					// flex: 1 1 calc(100% / var(--columns));
					// width: calc(100% / var(--columns));
					padding: 0.75rem 1rem 0.5rem;
					border-bottom: 1px solid var(--clr-grey-200);
					color: var(--clr-text);
					line-height: 1.4;
					cursor: pointer;
					transition: all 0.5s;

					&:hover,
					&:focus {
						color: #000;
						background: var(--clr-grey-200);
					}

					&:last-child {
						border-bottom: 0;
					}

					// 	&.disabled {
					// 		color: var(--clr-grey-200);
					// 		cursor: not-allowed;
					// 	}

					// 	// &:hover {
					// 	// 	color: var(--clr-red-400);
					// 	// 	border-color: var(--clr-red-400);
					// 	// 	transition: all 0;

					// 	// 	//   > .thumbnail::before {
					// 	// 	//     visibility: visible;
					// 	// 	//     opacity: 1;
					// 	// 	//   }
					// }

					// 	// &.excluded .thumbnail {
					// 	// 	filter: grayscale(1);
					// 	// 	opacity: 0.5;

					// 	// 	&:hover {
					// 	// 		filter: grayscale(0);
					// 	// 		opacity: 1;
					// 	// 	}
					// 	// }

					// 	// &:nth-child(1) {
					// 	//   > .thumbnail:hover {
					// 	//     transform: translateY(-1em);
					// 	//   }
					// 	// }

					// 	// &:nth-child(2) {
					// 	//   > .thumbnail:hover {
					// 	//     transform: translateY(-3em);
					// 	//   }
					// 	// }

					// 	// &::before {
					// 	//   display: inline-block;
					// 	//   font-style: normal;
					// 	//   font-variant: normal;
					// 	//   text-rendering: auto;
					// 	//   -webkit-font-smoothing: antialiased;
					// 	//   font-family: 'Font Awesome 5 Pro';
					// 	//   font-weight: 900;
					// 	//   content: '\f00c';

					// 	//   position: absolute;
					// 	//   color: var(--clr-green);
					// 	//   top: 0.6em;
					// 	//   left: 15px;
					// 	//   opacity: 0;
					// 	//   visibility: hidden;
					// 	// }

					// 	// &:hover::before,
					// 	// &.selected::before,
					// 	// &.excluded::before {
					// 	//   opacity: 1;
					// 	//   visibility: visible;
					// 	// }
				}

				li.parent {
					display: block;
					position: relative;
					grid-column-start: 1;
					grid-column-end: -1;

					.title {
						display: block;
						margin-bottom: calc(var(--gap) * 0.5);
						font-size: 1rem;
						font-weight: 600;
						text-transform: uppercase;
					}
				}

				// &.multiselect li {
				// 	&::before,
				// 	&:hover::before {
				// 		color: var(--clr-grey-200);
				// 	}

				// 	&.selected::before {
				// 		color: var(--clr-green);
				// 	}

				// 	&.excluded {
				// 		background: #f5f5f5;
				// 		color: var(--clr-grey-200);
				// 		padding-top: 0.1rem;
				// 		padding-bottom: 0.1rem;
				// 		cursor: not-allowed;

				// 		&::before {
				// 			font-family: 'Font Awesome 5 Pro';
				// 			content: '\f5ec';
				// 			/* color: desaturate(var(--clr-red), 50); */
				// 			color: var(--clr-red);
				// 			opacity: 0.5;
				// 		}

				// 		.item-name {
				// 			text-decoration: line-through;
				// 		}

				// 		.dependencies {
				// 			font-size: 0.8em;
				// 			/* color: lighten($grey-light, 20); */
				// 			color: var(--clr-grey-200);
				// 			text-decoration: none;
				// 		}

				// 		&:hover {
				// 			color: var(--clr-grey-200);

				// 			&::before {
				// 				color: var(--clr-red);
				// 				opacity: 1;
				// 			}

				// 			.dependencies {
				// 				color: var(--clr-grey-200);
				// 			}
				// 		}
				// 	}
				// }

				// > li.parent {
				// 	width: 100%;
				// 	padding: 0;
				// 	border: 0;
				// 	text-align: left;
				// 	cursor: default;

				// 	&:before {
				// 		display: none;
				// 	}

				// 	> span {
				// 		display: block;
				// 		margin: 2em 0 0.5em;
				// 		padding: 0;
				// 		text-transform: uppercase;
				// 		font-weight: 600;
				// 		font-size: 1rem;
				// 	}
				// }

				// > li {
				// 	.item-number {
				// 		display: inline-block;
				// 		vertical-align: top;
				// 		width: 30%;
				// 		font-weight: 600;
				// 	}

				// 	ul.categories {
				// 		display: inline-block;
				// 		vertical-align: top;
				// 		width: 70%;
				// 		margin: 0;
				// 		padding: 0 0 0 0.5em;

				// 		> li {
				// 			display: inline-block;

				// 			&::after {
				// 				content: ' | ';
				// 				color: var(--clr-green);
				// 				margin: 0 0.5em 0 0.2em;
				// 			}

				// 			&:last-child::after {
				// 				display: none;
				// 			}
				// 		}
				// 	}
				// }
			}
		}

		.module-content.has-tumbnails {
			.results {
				--gap: 1.5rem;
				--columns: 2;

				@media (min-width: 576px) {
					--gap: 1.5rem;
					--columns: 4;
				}

				li:not(.parent) {
					text-align: center;
					font-size: 0.9rem;
					border: 1px solid var(--clr-grey-200);
					border-radius: 15px;
					// hyphens: auto;

					&:hover,
					&:focus {
						color: #000;
						border-color: var(--clr-red-400);
						background: transparent;
					}
				}

				li.no-thumbnail {
					display: grid;
					align-content: center;
				}

				.flag {
					display: block;
				}

				.thumbnail {
					position: relative;
					isolation: isolate;
					width: 52px;
					height: 52px;
					display: block;
					margin: 0 auto 1em;
					border: 6px solid var(--clr-grey-200);
					border-radius: 50px;
					background: var(--clr-grey-200);
					overflow: hidden;
					transition: transform 1s;

					img {
						position: absolute;
						z-index: 10;
						top: 50%;
						left: 50%;
						transform: translate(-50%, -50%);
						width: 100%;
						height: 100%;
						object-fit: contain;
					}
				}

				li:hover > .thumbnail {
					transform: scale(1.35);
					transition: transform 0.4s;
				}
			}
		}
	}
}
</style>
