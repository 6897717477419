<template>
	<main class="module-content" v-bind:class="classObject">
		<h3>Wählen Sie eine Farbe.</h3>
		<div class="results" v-if="results">
			<ul>
				<template v-for="(item, index) in this.results">
					<li v-if="item.category" class="parent" :key="'parent-' + index">
						<span class="title">{{ item.category }}</span>

						<ul class="childs">
							<template v-for="(childItem, childIndex) in item.colors">
								<li v-if="childItem" :key="'child-' + childIndex" @click="setCurrentHangerAttribute(childIndex)" :class="{ 'no-thumbnail': !item.image }">
									<!-- <span class="label" @click="setCurrentHangerAttribute(childIndex)"> -->
									<div class="thumbnail" v-if="childItem.image">
										<img class="thumbnail-image" :src="childItem.image" alt="Vorschau" />
									</div>
									<span class="label">
										{{ childItem.label }}
										<span v-if="childItem.flag" class="flag">({{ childItem.flag }})</span>
									</span>
								</li>
							</template>
						</ul>
					</li>
					<li v-else :key="index" @click="setCurrentHangerAttribute(item.index)" :class="{ 'no-thumbnail': !item.image }">
						<div class="thumbnail" v-if="item.colors[0].image">
							<img class="thumbnail-image" :src="item.colors[0].image" alt="Vorschau" />
						</div>

						<span class="label">
							{{ item.colors[0].label }}
							<span v-if="item.colors[0].flag" class="flag">({{ item.colors[0].flag }})</span>
						</span>
					</li>
				</template>
			</ul>
		</div>
	</main>
</template>

<script>
export default {
	data: function () {
		return {
			results: Object,
			// fallbackThumbnail: require('@/assets/logo-weber-kleiderbuegel.svg'),
		};
	},

	computed: {
		classObject() {
			return {
				'has-tumbnails': true,
			};
		},
	},

	methods: {
		setCurrentHangerAttribute(value) {
			this.$store.commit('setCurrentHangerSelection', {
				key: 'colors',
				value: value,
			});
			this.$store.dispatch('checkConditions');
			this.$store.commit('closeModule');
		},

		colorsInCategories() {
			let colors = this.$store.state.currentCategory.colors;
			let newColors = [];

			for (let [index, currentColor] of Object.entries(colors)) {
				if (!currentColor.category) {
					// No category
					newColors[index] = [];
					newColors[index]['index'] = index;
					newColors[index]['colors'] = [currentColor];
					newColors[index]['category'] = false;
				} else {
					// Inside category
					if (!newColors[currentColor.category]) {
						newColors[currentColor.category] = [];
						newColors[currentColor.category]['index'] = index;
						newColors[currentColor.category]['colors'] = [];
						newColors[currentColor.category]['category'] = currentColor.category;
					}
					newColors[currentColor.category]['colors'][index] = currentColor;
				}
			}

			// New indexes
			let restructuredArr = [];
			for (let key in newColors) {
				restructuredArr.push(newColors[key]);
			}
			newColors = restructuredArr;

			return newColors;
		},

		/**
		 * Prevent broken images with fallback thumbnail
		 */
		// imageLoadError(event) {
		// 	event.target.src = this.fallbackThumbnail;
		// 	console.log('Image failed to load and is replaced with fallback thumbnail.');
		// },
	},

	mounted() {
		this.results = this.colorsInCategories();
	},
};
</script>

<style lang="scss">
// @media (min-width: 576px) {
// 	#overlay-module.shape .module {
// 		max-width: 500px;
// 	}
// }

#overlay-module.colors .module .module-content.has-tumbnails .results .thumbnail img {
	width: 900px;
	height: 900px;
	transform: translate(-52%, -42%);
}

// #overlay-module.color .module .module-content.has-tumbnails .results {
// 	--columns: 2;
// 	--gap: 0.5rem;
// }
</style>
