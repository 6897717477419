<template>
	<button v-bind:class="[attributeSlug, classObject]" @click="switchModule(attributeSlug)" v-if="optionsCount > 0">
		<span v-if="attributeLabel" class="label" v-html="attributeLabel"></span>
		<span class="value" v-html="currentValue"></span>
	</button>
</template>

<script>
export default {
	name: 'ButtonDefault',
	props: ['attributeSlug', 'attributeLabel'],

	computed: {
		classObject() {
			return {
				isEditable: true,
				toggleButton: false,
				checked: false,
			};
		},

		currentValue() {
			if (!this.currentAttrHasValues) return 'nicht verfügbar';

			let id = this.currentAttrId();
			let value = this.$store.state.currentCategory[this.attributeSlug][id];

			let label = this.$store.state.currentCategory[this.attributeSlug]?.[id]?.label;
			if (label) {
				value = label;
			}

			let category = this.$store.state.currentCategory[this.attributeSlug]?.[id]?.category;
			if (category) {
				value = category + ' > ' + label;
			}

			let unit = this.$store.state.currentCategory[this.attributeSlug + '-unit'];
			if (unit) value = value + ' ' + unit;

			this.setToggleButton();

			return value;
		},

		currentAttrHasValues() {
			return this.$store.state.currentCategory[this.attributeSlug] ? true : false;
		},

		optionsCount() {
			if (!this.$store.state.currentCategory[this.attributeSlug]) return 0;

			return Object.keys(this.$store.state.currentCategory[this.attributeSlug]).length;
		},
	},

	methods: {
		currentAttrId() {
			return this.$store.state.currentHangerSelection[this.attributeSlug];
		},

		switchModule(module) {
			if (this.isToggleButton()) {
				let currentAttrValues = this.$store.state.currentCategory[this.attributeSlug];
				let currentAttrId = this.currentAttrId();
				let newValue = false;
				for (const value of Object.entries(currentAttrValues)) {
					if (currentAttrId != value[0]) newValue = value[0];
				}

				this.$store.commit('setCurrentHangerSelection', {
					key: this.attributeSlug,
					value: newValue,
				});
				this.$store.dispatch('checkConditions');
				return false;
			}

			if (this.classObject.isEditable) {
				this.$store.commit('switchModule', module);
			}
		},

		getOptionsCount() {
			return this.optionsCount;
		},

		isToggleButton() {
			let currentAttrValues = this.$store.state.currentCategory[this.attributeSlug];
			let currentAttrValuesCount = Object.entries(currentAttrValues).length;
			let hasYes = false;
			let hasNo = false;

			if (currentAttrValuesCount == 2) {
				for (const value of Object.entries(currentAttrValues)) {
					hasYes = value[1].label && value[1].label == 'Ja' ? true : hasYes;
					hasNo = value[1].label && value[1].label == 'Nein' ? true : hasNo;
				}
			}

			if (hasYes && hasNo) {
				return true;
			}

			return false;
		},

		setToggleButton() {
			this.classObject.toggleButton = false;

			if (this.isToggleButton()) {
				this.classObject.toggleButton = true;
				this.setToggleButtonIsChecked();
			}
		},

		setToggleButtonIsChecked() {
			this.classObject.checked = false;

			let id = this.currentAttrId();
			let value = this.$store.state.currentCategory[this.attributeSlug][id];

			if (value.label == 'Ja') {
				this.classObject.checked = true;
			}
		},

		setIsEditable() {
			this.classObject.isEditable = false;
			if (this.getOptionsCount() > 1) {
				this.classObject.isEditable = true;
			}
		},
	},

	watch: {
		optionsCount: function () {
			this.setIsEditable();
		},
	},

	created() {
		this.setIsEditable();
	},
};
</script>
