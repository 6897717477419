<template>
	<div class="home">
		<Header />
		<HangerConfigurator />
		<PageFooter />
		<Module />
	</div>
</template>

<script>
// @ is an alias to /src
import Header from '@/components/PageHeader.vue';
import HangerConfigurator from '@/components/HangerConfigurator.vue';
import PageFooter from '@/components/PageFooter.vue';
import Module from '@/components/modules/ChooseModule.vue';

export default {
	name: 'ViewConfigurator',
	components: {
		Header,
		HangerConfigurator,
		PageFooter,
		Module,
	},
};
</script>
