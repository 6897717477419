<template>
	<!-- <button v-bind:class="classObject" @click="switchModule('category')">Wählen Sie eine Kategorie</button> -->
	<button v-bind:class="classObject" @click="switchModule('category')">
		<span v-html="attributeLabel"></span>
	</button>
</template>

<script>
export default {
	name: 'ButtonCategory',
	data: function () {
		return {
			classObject: {
				category: true,
				isEditable: true,
			},
		};
	},

	computed: {
		attributeLabel() {
			return Object.keys(this.$store.state.currentCategory).length > 0 ? this.$store.state.currentCategory.name : 'Wählen Sie eine Kategorie';
		},
	},

	methods: {
		switchModule(module) {
			this.$store.commit('switchModule', module);
		},
	},
};
</script>
