<template>
	<main id="hanger-configurator">
		<!-- <HangerConfiguratorWelcomeScreen /> -->
		<img src="@/assets/fingerprint.svg" alt="Fingerabdruck" class="fingerprint" />
		<div class="loading-category-wrapper" v-if="loadingCategory">
			<div class="loading-category-info loading">
				<i class="fas fa-spinner fa-spin"></i>
				Einen Moment bitte. Wir holen unsere Bügel aus dem Lager.
			</div>
		</div>
		<div class="category-selection-wrapper" v-else-if="selectCategory">
			<!-- <div class="categorie-selection" v-if="selectCategory"> -->
			<CategorySelection />
		</div>
		<div class="configurator-components" v-else>
			<HangerSettingsGeneral />
			<HangerSettingsAdditional />
			<HangerImage />
			<HangerConfiguratorItemNumber />
		</div>
	</main>
</template>

<script>
import CategorySelection from '@/components/CategorySelection.vue';

import HangerSettingsGeneral from '@/components/HangerSettingsGeneral.vue';
import HangerSettingsAdditional from '@/components/HangerSettingsAdditional.vue';
import HangerImage from '@/components/HangerImage.vue';
import HangerConfiguratorItemNumber from '@/components/hanger-configurator/ItemNumber.vue';

export default {
	name: 'HangerConfigurator',

	// data: function () {
	// 	return {
	// 		selectCategory: true,
	// 	};
	// },

	computed: {
		loadingCategory() {
			return this.$store.state.currentCategoryIsLoading;
		},

		selectCategory() {
			if (this.$store.state.currentCategory && Object.keys(this.$store.state.currentCategory).length) {
				return false;
			}
			return true;
		},
	},

	components: {
		CategorySelection,
		HangerSettingsGeneral,
		HangerSettingsAdditional,
		HangerImage,
		HangerConfiguratorItemNumber,
	},

	mounted() {
		this.selectedCategory = true;

		// Preselect Category
		const categoryId = this.$route.query.cid;
		if (categoryId) {
			this.$store.commit('prepareCurrentCategory', {
				categoryId: categoryId,
			});

			// this.$router.replace({ query: { cid: categoryId } });
		}

		// if (this.$store.state.hangerList.length > 1) return false;
		// this.loadingHangerList = true;

		// // Load the hangers from backend
		// fetch('https://weber3000.de/wp-json/rwb-hanger/v2/hanger/')
		// 	.then((r) => r.json())
		// 	.then((response) => {
		// 		this.loadingHangerList = false;
		// 		this.$store.commit('setHangersList', { content: response });

		// 		// this.$store.commit('prepareCurrentHanger', {
		// 		//   slug: '129'
		// 		// })
		// 	})
		// 	.catch((error) => {
		// 		console.error('Error:', error);
		// 	});
	},
};
</script>

<style lang="scss" scoped>
.category-selection-wrapper {
	margin: 2rem 3% 1rem;
}

.fingerprint {
	position: absolute;
	z-index: -1;
	top: 50%;
	right: 0;
	transform: translateY(-50%);
}

.loading-category-wrapper {
	margin: 4rem 3%;
	text-align: center;
	font-size: 1.3em;

	i {
		display: block;
		font-size: 6rem;
		margin-bottom: 2rem;
	}
}

// .loading-hanger-list-info {
// 	display: inline-block;
// 	width: auto;
// 	height: auto;
// 	appearance: none;
// 	margin: 0 1em;
// 	padding: 0.8em 2.5em 0.5em;
// 	border: 1px solid var(--clr-green-400);
// 	border-radius: 100px;
// 	font-size: 1.2rem;
// 	font-weight: 600;
// 	color: var(--clr-text);
// 	background: transparent;
// 	cursor: wait;
// 	transition: all 0.3s;

// 	// Fontawesome Icon
// 	i {
// 		margin-right: 0.7em;
// 		font-weight: 400;
// 	}
// }

#hanger-configurator {
	margin: 2rem auto 1rem;
	max-width: 1580px;
}

.configurator-components {
	margin: 0 3%;
	display: grid;
	grid-template-areas:
		'settingsGeneral'
		'settingsAdditional'
		'hangerImage'
		'itemNumber';

	@media (min-width: 768px) {
		margin: 0 0 0 3%;
		grid-template-areas:
			'settingsGeneral    settingsGeneral itemNumber'
			'settingsAdditional hangerImage     itemNumber';
		grid-template-columns: auto 3fr minmax(auto, 260px);
	}
}
// #hanger-configurator {
// margin-top: auto;
// margin-bottom: auto;
// flex-grow: 1;
// display: flex;
// align-items: center;
// justify-content: center;

// > div {
//   flex-grow: 1;
// }

// > .configurator {
//   height: 100%;
//   display: flex;
//   flex-direction: column;

//   #hanger-image {
//     flex-grow: 1;
//   }
// }

// .configurator > .previewToggle {
//   margin: -1em 0 2em;

//   @media (min-width: 576px) {
//     display: none;
//   }
// }

// footer {
//   line-height: 1;
//   color: var(--clr-grey-300);
//   text-align: center;
//   margin-top: 2em;
//   margin-bottom: 2em;

//   @media (min-width: 576px) {
//     margin-top: 1.4em;
//     margin-bottom: 0;
//   }

//   @media (min-width: 992px) {
//     display: flex;
//     flex-direction: row;
//     align-items: center;
//     justify-content: center;
//   }

//   > div {
//     display: inline-block;
//     margin: 0.5em 1.5em;
//     padding-left: 15px;
//     padding-right: 15px;

//     &:nth-child(1) {
//       order: 2;
//     }

//     &:nth-child(2) {
//       order: 1;
//       display: none;
//     }

//     &:nth-child(3) {
//       order: 3;
//       width: 100%;
//       margin-left: 0;
//       margin-right: 0;
//     }

//     @media (min-width: 576px) {
//       &:nth-child(2) {
//         display: inline-block;
//       }

//       &:nth-child(3) {
//         width: auto;
//         margin-left: 0.5em;
//         margin-right: 0.5em;
//       }
//     }

//     @media (min-width: 992px) {
//       margin: 0 1.5em;
//       flex-basis: auto;

//       // &:first-child {
//       //   margin-left: auto;
//       // }

//       // &:last-child {
//       //   margin-right: auto;
//       // }
//     }
//   }
// }
// }
</style>
